@import url(https://fonts.googleapis.com/css?family=Montserrat);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.home{
    display:flex;
    /*background-color: #111;*/
}

.background{
    width:100%;
    opacity: 0.2;
}
.info{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleBox{
    font-family: "Montserrat";
    text-align: center;
    color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 500px;
    letter-spacing: 1px;
}

.title{
    background-image: url(https://media.giphy.com/media/l41lHDSvmwnQGDUD6/giphy.gif);
    /*https://media.giphy.com/media/elzCnIQAjQMWA/giphy.gif*/
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    text-transform: uppercase;
    font-size: 120px;
    line-height: .75;
    margin: 10px 0;
}

.subtitle{
    align-items: center;
    justify-content: center;
    color: #000000;
    letter-spacing: 1px;
    text-align: center;
    font-weight: 600;
}

.play{
    display: inline-block;
    padding: 0.3em 1.2em;
    margin: 20px 10px;
    border: 0.16em solid rgba(255, 255, 255, 0);
    border-radius: 2em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-size:20px;
    font-weight: 400;
    color: #FFFFFF;
    text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
    text-align: center;
    transition: all 0.2s;
    background-color: #ec0d4c;
}
.play:hover{
    border-color: rgba(255,255,255,1);
}

.githubBox{
    bottom:0;
    right:0;
    position: fixed;
}

.githubLogo{
    width: 100px;
    height: 50px;
}

@media all and (max-width: 800px) {
    .info{
        width: 100%
    }
    .titleBox{
        height: 300px;
    }
    .title{
        font-size: 40px;
        margin: 0;
    }
}

.cityBox{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.background{
    opacity: 0.2;
    width: 100%;
    position: absolute;
    height: 100%;
}

.actionsBox{
    position: absolute;
    bottom: 150px;
}
.cityTitleBox {
    display: flex;
}
.cityTitle{
    font-weight: 600;
    font-size: 32px;
}

.population{
    display: flex;
}

.countryFlag{
    margin-left: 20px;
}

@media all and (max-width: 800px) {
    .cityTitle{
        font-weight: 600;
        font-size: 18px;
    }
    .actionsBox{
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: inherit;
        bottom: auto;
    }
}

body {
    background: linear-gradient(-45deg, #ee7752, #d2b111, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.game {
height: 100vh;
}

.box{
    display: flex;
    height: 100%;
}

.actionButtonsBox{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.boxFirstCity{
    border:solid;
    border-color: black;
    border-radius: 5px;
    width: 50%;
    height:100%;
}
.boxSecondCity{
    border:solid;
    border-color: black;
    border-radius: 5px;
    width: 50%;
    height:100%;
}

.score{
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 50px;
}

.highScore{
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 10px;
}
.actionButton{
    display: inline-block;
    padding: 0.5em 2.0em;
    margin: 20px 10px;
    border: 0.16em solid rgba(255, 255, 255, 0);
    border-radius: 2em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #000000;
    text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
    text-align: center;
    transition: all 0.2s;
    background-color: #f1bb4e;
}

.actionButton:hover{
    border-color: rgba(255,255,255,1);
}

@media all and (max-width: 800px) {
    .box{
        display:block;
        height: 50%;
    }
    .highScore{
        top: 0;
    }
    .score{
        top: 0;
        margin-right: 10px;
    }
    .scoreText{
        font-size: 20px;
    }
    .highScoreText{
        font-size: 20px;
    }
    .boxFirstCity{
        width: 100%;
    }
    .boxSecondCity{
        width: 100%;
    }
}

.lost{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: black;
    height: 100vh;
}
.memeImage{
    width: 500px;
    height: 400px;
}

.link {
    display: inline-block;
    padding: 0.3em 1.2em;
    margin: 20px 10px;
    border: 0.16em solid rgba(255, 255, 255, 0);
    border-radius: 2em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #FFFFFF;
    text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
    text-align: center;
    transition: all 0.2s;
    background-color: #4e9af1;
}

.link:hover{
    border-color: rgba(255,255,255,1);
}
.lostTitle{
    color: white;
}
.lostScoreText {
    color: white
}
.lostScore{
    font-size: 48px;
    font-weight: 300;
    color: #f1bb4e;
    margin:10px;
}


@media all and (max-width: 800px) {
    .memeImage{
        width: 300px;
        height: 300px;
    }
}


