.cityBox{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.background{
    opacity: 0.2;
    width: 100%;
    position: absolute;
    height: 100%;
}

.actionsBox{
    position: absolute;
    bottom: 150px;
}
.cityTitleBox {
    display: flex;
}
.cityTitle{
    font-weight: 600;
    font-size: 32px;
}

.population{
    display: flex;
}

.countryFlag{
    margin-left: 20px;
}

@media all and (max-width: 800px) {
    .cityTitle{
        font-weight: 600;
        font-size: 18px;
    }
    .actionsBox{
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: inherit;
        bottom: auto;
    }
}
