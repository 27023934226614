.lost{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: black;
    height: 100vh;
}
.memeImage{
    width: 500px;
    height: 400px;
}

.link {
    display: inline-block;
    padding: 0.3em 1.2em;
    margin: 20px 10px;
    border: 0.16em solid rgba(255, 255, 255, 0);
    border-radius: 2em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #FFFFFF;
    text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
    text-align: center;
    transition: all 0.2s;
    background-color: #4e9af1;
}

.link:hover{
    border-color: rgba(255,255,255,1);
}
.lostTitle{
    color: white;
}
.lostScoreText {
    color: white
}
.lostScore{
    font-size: 48px;
    font-weight: 300;
    color: #f1bb4e;
    margin:10px;
}


@media all and (max-width: 800px) {
    .memeImage{
        width: 300px;
        height: 300px;
    }
}

