@import url('https://fonts.googleapis.com/css?family=Montserrat');

.home{
    display:flex;
    /*background-color: #111;*/
}

.background{
    width:100%;
    opacity: 0.2;
}
.info{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleBox{
    font-family: "Montserrat";
    text-align: center;
    color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 500px;
    letter-spacing: 1px;
}

.title{
    background-image: url(https://media.giphy.com/media/l41lHDSvmwnQGDUD6/giphy.gif);
    /*https://media.giphy.com/media/elzCnIQAjQMWA/giphy.gif*/
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    text-transform: uppercase;
    font-size: 120px;
    line-height: .75;
    margin: 10px 0;
}

.subtitle{
    align-items: center;
    justify-content: center;
    color: #000000;
    letter-spacing: 1px;
    text-align: center;
    font-weight: 600;
}

.play{
    display: inline-block;
    padding: 0.3em 1.2em;
    margin: 20px 10px;
    border: 0.16em solid rgba(255, 255, 255, 0);
    border-radius: 2em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-size:20px;
    font-weight: 400;
    color: #FFFFFF;
    text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
    text-align: center;
    transition: all 0.2s;
    background-color: #ec0d4c;
}
.play:hover{
    border-color: rgba(255,255,255,1);
}

.githubBox{
    bottom:0;
    right:0;
    position: fixed;
}

.githubLogo{
    width: 100px;
    height: 50px;
}

@media all and (max-width: 800px) {
    .info{
        width: 100%
    }
    .titleBox{
        height: 300px;
    }
    .title{
        font-size: 40px;
        margin: 0;
    }
}
