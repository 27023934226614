body {
    background: linear-gradient(-45deg, #ee7752, #d2b111, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.game {
height: 100vh;
}

.box{
    display: flex;
    height: 100%;
}

.actionButtonsBox{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.boxFirstCity{
    border:solid;
    border-color: black;
    border-radius: 5px;
    width: 50%;
    height:100%;
}
.boxSecondCity{
    border:solid;
    border-color: black;
    border-radius: 5px;
    width: 50%;
    height:100%;
}

.score{
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 50px;
}

.highScore{
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 10px;
}
.actionButton{
    display: inline-block;
    padding: 0.5em 2.0em;
    margin: 20px 10px;
    border: 0.16em solid rgba(255, 255, 255, 0);
    border-radius: 2em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #000000;
    text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
    text-align: center;
    transition: all 0.2s;
    background-color: #f1bb4e;
}

.actionButton:hover{
    border-color: rgba(255,255,255,1);
}

@media all and (max-width: 800px) {
    .box{
        display:block;
        height: 50%;
    }
    .highScore{
        top: 0;
    }
    .score{
        top: 0;
        margin-right: 10px;
    }
    .scoreText{
        font-size: 20px;
    }
    .highScoreText{
        font-size: 20px;
    }
    .boxFirstCity{
        width: 100%;
    }
    .boxSecondCity{
        width: 100%;
    }
}
